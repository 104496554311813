.timesContainer{
    display: flex;
    border: 1px solid #cccccc;

    align-items: center;
}
@media(max-width: 1024px){
    .timesContainer{
        flex-direction: column;
    }
}
.timesContainer div{
    padding: 0.5rem;
}
.timesContainer div div{
    display: flex;
}
.timesContainer div:nth-child(even){
    background-color: #efefef;
}
.timesContainer input[type="submit"]{
    margin: 0.25rem;
    height: 56px;
}