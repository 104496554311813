.half{
    width: 50%;
}
.menusContainer{
    display: flex;
}

.menuDownloadButtonsContainer{
    display: flex;
    flex-direction: column;
}

.openingTimesContainer{
    display: flex;  
}
.container{
    
}

.top{
    padding: 2rem;
}

.menus {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}