.bannerContainer{
    width: 100%;
    background-color: #333333;
    color: #efefef;
    z-index: 5;
    padding: 2rem 1rem 1rem 1rem;
}

.bannerContainer h2{
    margin: 0;
}